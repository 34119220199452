//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'
import BenefitsBoomerang from '../../components/marketing/benefitsBoomerang'
import TemplateCalloutLink from '../../components/elements/templateCalloutLink'

const useCaseData = [
  {
    title: 'Increase leads and close deals with visual social marketing',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description:
      'Keep your lead pipeline stocked, close deals more quickly, and stay in touch with prospects with compelling social content',
    descriptionColor: 'white-3',
    image: 'industryRealestate',
    iconShow: true,
    iconContext: 'home',
    // iconColor: 'white',
    iconfill: 'white',
    iconcolor: 'white',
    // lineClass: 'green-1',
    // bgcolor: 'dkgreen-8',
    // border: 'border-top-2-dkgreen-1',
    bggradient: 'cirrus',
    expandcol: 5,
    buttonText: 'See real estate templates',
    buttonPath: '/tag/real%20estate',
    buttonClass: 'btn-cta-outline',
  },
]

const industryExamples = [
  {
    title: 'New property listings',
    titlecolor: 'yellow-1',
    desccolor: 'black-1',
    image: 'exampleRealEstate1',
    imageShadow: true,
    imageBorderColor: 'white-1',
    lineClass: 'yellow-5',
    iconShow: true,
    iconContext: 'home',
    iconColor: 'yellow',
    description:
      'Highlight new listings via drag-and-drop visual marketing templates and drive awareness with prospects through rapid sharing',
  },
  {
    title: 'Awareness with prospects',
    titlecolor: 'orange-1',
    desccolor: 'black-1',
    image: 'exampleRealEstate2',
    imageShadow: true,
    imageBorderColor: 'white-1',
    lineClass: 'orange-4',
    iconShow: true,
    iconContext: 'people',
    iconColor: 'orange',
    description:
      'Share new listings, area information, and market trends with prospects on social through easily customizable templates',
  },
  {
    title: 'Content marketing',
    description:
      'Stay top-of-mind with your network when you share relevant visual marketing via templates: area info, recipes, tips & tricks and more ',
    image: 'exampleRealEstate3',
    imageShadow: true,
    imageBorderColor: 'white-1',
    iconShow: true,
    iconContext: 'newspaper',
    iconColor: 'bluegreen',
    // lineClass: 'bluegreen-4',
  },
]

const featureData = {
  title: 'Close deals more quickly with visual marketing',
  titlecolor: 'black-4',
  mode: 'triovertical',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'New property listings',
          titlecolor: 'purple-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'purple-1',
          iconname: 'newspaper',
          iconcolor: 'purple',
          path: '/platform/content-marketing/',
          description:
            'Highlight new listings via drag-and-drop visual marketing templates and drive awareness with prospects through rapid sharing',
        },
        {
          title: 'Awareness with prospects',
          titlecolor: 'turquoise-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'turquoise-1',
          iconname: 'megaphone',
          iconcolor: 'turquoise',
          path: '/platform/offers-promotions/',
          description:
            'Share new listings, area information, and market trends with prospects on social through easily customizable templates',
        },
        {
          title: 'Content marketing',
          titlecolor: 'bluegreen-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'bluegreen-1',
          iconname: 'people',
          iconcolor: 'bluegreen',
          path: '/platform/lead-generation/',
          description:
            'Stay top-of-mind with your network when you share relevant visual marketing via templates: area info, recipes, tips & tricks and more ',
        },
      ],
    },
  ],
}

const templateCallout = {
  noun: 'real estate business',
  adjective: 'real estate',
  path: '/tag/real%20estate',
  description:
    'See real estate, listing & open house templates from our community',
}
const About = (props) => (
  <Layout>
    <Helmet
      title="Storylava - Real Estate - Visual Content Marketing"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how Storylava can help you drive more real estate leads and close them with visual content marketing',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium purple-1">
            Close deals and drive real estate leads
          </h1>
          <p class="display-4">
            With your busy, on-the-go schedule, you can nurture and close your
            real estate leads with visual marketing
          </p>
        </div>
      </div>
    </div>

    <AlternatingCallout featureData={useCaseData} />

    {/* SELECTED FEATURES */}
    {/* <FeatureQuad featureData={featureData} /> */}
    <AlternatingCallout shift={true} featureData={industryExamples} />
    {/* END SELECTED FEATURES */}

    <TemplateCalloutLink data={templateCallout} />
  </Layout>
)

export default About
